.App {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
  width: 85%;
}

.Content {
  width: 100%;
}